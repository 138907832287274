import React from 'react';
import BMK10yearsFanfare from '../../videos/BMK-fanfare-10-anniversary.mp4';

interface Project {
    title: string;
    subtitle?: string;
    description?: string;
    mediaClip?: React.ReactNode;
}

const projects: Project[] = [
    {
        title: 'Fanfare for Birkelunden mannskor (2022)',
        subtitle: 'Composition for small orchestra and male choir.',
        description:
            "Opening fanfare for Birkelunden mannskor's 10 year anniversary, performed in the Oslo Concert Hall in november 2022.",
        mediaClip: (
            <video width="100%" controls>
                <source src={BMK10yearsFanfare} type="video/mp4" />
            </video>
        ),
    },
    {
        title: 'Limits (2021)',
        subtitle:
            'Composition for string quartet, vibraphone (440hz) and quarter tone vibraphone (430 hz)',
        description:
            'Commission for a collaboration between the string quartet Quartetto Testosterone and the percussion duo PERCelleh. The piece is called Limits, and explores the boundaries of opposing musical layers in a microtonal soundscape. The composition premiered at the Interpuls festival in Jakobskirken in february 2022.',
        mediaClip: (
            <iframe
                title="Limits - Aleksander Waaktaar(2021)"
                width="100%"
                height="330"
                src="https://www.youtube-nocookie.com/embed/0lDGHaWq8QI"
                frameBorder="0"
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
        ),
    },
    {
        title: 'Sleep Deprivation (2016)',
        subtitle:
            'Composition for a 14-piece chambre ensemble with rhythm section ',
        mediaClip: (
            <iframe
                style={{ paddingBottom: 10 }}
                title="Sleep Deprivation"
                width="100%"
                height="130"
                scrolling="no"
                frameBorder="no"
                src={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/464249673&color=%235c5c5c&show_artwork=false&show_playcount=false&show_comments=false`}
            ></iframe>
        ),
    },
];

export default projects;
