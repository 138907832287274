import React from 'react';
import styled from 'styled-components';

import projects from './projects';
import Arrangement from './Arrangement';
import { useScrollToTop } from '../../shared/useScrollToTop';

const Container = styled.div`
    margin-bottom: 50px;

    *:not(:last-child) {
        padding-right: 50px;
    }

    p {
        margin-bottom: 20px;
    }
`;
const Arranger: React.FC = () => {
    useScrollToTop();
    return (
        <>
            <Container>
                <div style={{ maxWidth: 700 }}>
                    <p>
                        I write arrangements for ensembles of all sizes, in
                        various genres. Some of the ensembles and orchestras I
                        have written for include: Oslo Philharmonic Orchestra,
                        Norwegian Radio Broadcast Orchestra (KORK), Stavanger
                        Symphony Orchestra, Kristiansand Symphony Orchestra,
                        Arctic Philharmonic, Opera North choir & orchestra, The
                        Norwegian Wind Ensemble and Sjøforsvarets musikkorps
                        (SFMK).
                    </p>
                    <p>
                        I have also done collaborations with artists such as
                        Konradsen, Beharie, Girl in Red, Dagny, Thomas Dybdahl,
                        Marthe Wang, Frida Ånnevik, Gabrielle, Isah, Trygve
                        Skaug, Ellen Andrea Wang, Astrid S, Arve Henriksen, Jan
                        Bang, Eivind Aarset, Jakop Jansønn, Lemaitre and
                        Yoshinori Hayashi.
                    </p>
                </div>
            </Container>
            <h1>Selected projects</h1>
            <div>
                {projects
                    .sort(
                        (a, b) =>
                            b.year - a.year ||
                            (a.order && b.order ? a.order - b.order : 0)
                    )
                    .map((project) => (
                        <Arrangement
                            project={project}
                            key={project.title + project.commissioner}
                        />
                    ))}
            </div>
        </>
    );
};

export default Arranger;
