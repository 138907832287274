import React from 'react';

import styled from 'styled-components';
import projects from './projects';
import { useScrollToTop } from '../../shared/useScrollToTop';

const Container = styled.div`
    margin-bottom: 70px;
`;

const Wrapper = styled.div`
    display: flex;

    @media (max-width: 767px) {
        flex-direction: column;
    }
`;

const Paragraph = styled.p`
    max-width: 550px;
    margin-bottom: 30px;
`;

const ProjectInfo = styled.div`
    max-width: 550px;
    margin-bottom: 40px;
    @media (max-width: 767px) {
        max-width: 100%;
        margin-left: 0;
    }
`;

const AlbumIframe = styled.iframe`
    width: 450px;
    margin-right: 6px;

    @media (max-width: 767px) {
        width: 100%;
        margin-right: 0;
    }
`;

const Compositions: React.FC = () => {
    useScrollToTop();
    return (
        <>
            <Container>
                <Paragraph>
                    As a composer, I explore the boundaries between jazz, pop,
                    classical and contemporary music, in many different formats.
                </Paragraph>
                <h1>Solo project</h1>
                <h2>Feller (2023)</h2>
                <Paragraph>
                    <i>Feller</i> is a collection of quiet and introspective
                    songs in chamber music-like arrangements interspersed with
                    Martin Miguel Almagro Tonnes's (producer) guitars and
                    creative whimsies.
                </Paragraph>
                <h2>All wrong (2020)</h2>
                <Paragraph>
                    My first album, <i>All Wrong</i>, was released on the label
                    Koke Plate in the spring of 2020. The album consists of
                    quiet songs with stories and reflections from my own life.
                    All Wrong is a collaboration with producer and guitarist
                    Martin Miguel Almagro Tonne.
                </Paragraph>
                <Wrapper>
                    <AlbumIframe
                        title="Aleksander Waaktaar - Feller Album"
                        src="https://open.spotify.com/embed/album/7CncF0FuIND7CUa6TQXAmV"
                        width="100%"
                        height="360"
                        frameBorder="0"
                        allow="encrypted-media"
                    ></AlbumIframe>
                    <AlbumIframe
                        title="Aleksander Waaktaar - All Wrong Album"
                        src="https://open.spotify.com/embed/album/1np3iNbVsT2xo9LshCzzvB"
                        width="100%"
                        height="360"
                        frameBorder="0"
                        allow="encrypted-media"
                    ></AlbumIframe>
                </Wrapper>
            </Container>

            <h1>Other works</h1>
            {projects.map((project) => (
                <ProjectInfo>
                    <h2 style={{ marginTop: 0 }}>{project.title}</h2>
                    {project.subtitle && (
                        <p
                            style={{
                                fontSize: 18,
                                marginBottom: 12,
                            }}
                        >
                            {project.subtitle}
                        </p>
                    )}
                    {project.description && (
                        <p style={{ marginBottom: 16 }}>
                            {project.description}
                        </p>
                    )}
                    {project.mediaClip}
                </ProjectInfo>
            ))}
        </>
    );
};

export default Compositions;
