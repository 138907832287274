import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const HomeLink = styled(Link)`
    font-size: 24px;
    font-weight: 400;
`;

const CV = styled(Link)`
    border: 1px solid black;
    padding: 10px 30px;
    align-items: center;
    position: relative;
    margin-left: 20px;

    @media (pointer: fine) {
        &:before,
        &:after {
            width: calc(100% + 1px);
            height: calc(100% + 1px);
            content: '';
            position: absolute;
            top: -1px;
            left: -1px;
            transform: scale(0);
            transition: transform 1s;
        }

        &:hover:before,
        &:hover:after {
            transform: scale(1);
        }

        &:before {
            border-bottom: 1px solid white;
            border-left: 1px solid white;
            transform-origin: 0 100%;
        }

        &:after {
            border-top: 1px solid white;
            border-right: 1px solid white;
            transform-origin: 100% 0%;
        }
    }
`;

const MenuContainer = styled.nav`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 60px 0;
    font-size: 18px;

    a {
        text-decoration: none;
    }
`;

const Menu: React.FC = () => {
    return (
        <MenuContainer>
            <HomeLink to="/">Aleksander Sinding-Larsen Waaktaar</HomeLink>
            <CV to="/cv">CV</CV>
        </MenuContainer>
    );
};

export default Menu;
