import React from 'react';
import { useParams } from 'react-router-dom';
import { Slug, details } from './projects';
import styled from 'styled-components';
import { useScrollToTop } from '../../shared/useScrollToTop';

const Grid = styled.ul`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 12px;
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 12px;

    @media (max-width: 650px) {
        grid-template-columns: 1fr 1fr;
    }
`;

const Section = styled.section`
    margin-bottom: 25px;
`;

const FrontImage = styled.div`
    width: 40%;
    margin-left: 12px;
    margin-top: 20px;
    @media (max-width: 650px) {
        width: 100%;
        margin-left: 0;
    }
`;

const Content = styled.div(
    (props: { hasOnlyFrontImage: boolean }) => `
    ${
        props.hasOnlyFrontImage
            ? `
        maxWidth: 100%;
        display: flex;
        justify-content: space-between;`
            : 'max-width: 900px;'
    }

    @media (max-width: 650px) {
        flex-direction: column;
    }
`
);

const Sections = styled.div(
    (props: { hasOnlyFrontImage: boolean }) => `
    width: ${props.hasOnlyFrontImage ? '55%' : '100%'};

    @media (max-width: 650px) {
        width: 100%;
        flex-direction: column;
    }
`
);

export default function FilmAndTvDetail() {
    useScrollToTop();
    const { slug } = useParams();

    if (!slug) {
        return null;
    }
    const detail = details[slug as Slug];

    if (!detail) {
        return null;
    }

    return (
        <Content hasOnlyFrontImage={detail.images.length === 0}>
            <Sections hasOnlyFrontImage={detail.images.length === 0}>
                <h1>{detail.metadata.title}</h1>
                <div style={{ marginBottom: 25 }}>
                    {detail.tags.map((tag) => (
                        <span
                            style={{
                                backgroundColor: 'rgb(162, 194, 215)',
                                padding: '5px 15px',
                                borderRadius: '15px',
                                marginRight: 5,
                            }}
                        >
                            {tag}
                        </span>
                    ))}
                </div>
                <Section style={{ maxWidth: 600 }}>
                    {detail.description}
                </Section>
                {detail.mediaClip && (
                    <Section style={{ maxWidth: 596 }}>
                        {detail.mediaClip}
                    </Section>
                )}
                {detail.images.length > 0 && (
                    <Section>
                        <Grid>
                            {detail.images.map((image) => (
                                <li>
                                    <img
                                        style={{
                                            width: '100%',
                                            display: 'block',
                                        }}
                                        alt=""
                                        src={image}
                                    />
                                </li>
                            ))}
                        </Grid>
                        <p>{detail.credit}</p>
                    </Section>
                )}
                {detail.secondaryMediaClip && (
                    <Section>{detail.secondaryMediaClip}</Section>
                )}
            </Sections>
            {detail.images.length === 0 && (
                <FrontImage>
                    <img
                        src={detail.metadata.frontImage}
                        alt=""
                        style={{ width: '100%' }}
                    />
                </FrontImage>
            )}
        </Content>
    );
}
