import React from 'react';
import styled from 'styled-components';

import { Project } from './projects';

const ProjectYear = styled.div`
    margin-bottom: 10px;
    font-weight: 400;
`;

const ProjectCommissioner = styled.div`
    margin-bottom: 7px;
    font-size: 18px;
`;

const ProjectLink = styled.div`
    margin-top: 10px;
`;

const ProjectMedia = styled.div`
    margin-left: 30px;
    max-width: 515px;
    width: 100%;

    @media (max-width: 767px) {
        margin-left: 0;
        margin-top: 20px;
    }
`;

const ProjectMediaSmall = styled.div`
    max-width: 515px;
    margin-top: 15px;
`;

const ArrangementContainer = styled.div`
    margin: 40px 0 70px;
    display: flex;
    justify-content: space-between;
    max-width: 900px;

    @media (max-width: 767px) {
        flex-direction: column;
    }
`;

interface ArrangementProps {
    project: Project;
}

const Arrangement: React.FC<ArrangementProps> = ({ project }) => {
    return (
        <ArrangementContainer>
            <div style={{ width: '100%', maxWidth: 550 }}>
                <h2>{project.title}</h2>
                {project.commissioner && (
                    <ProjectCommissioner>
                        {project.commissioner}
                    </ProjectCommissioner>
                )}
                <ProjectYear>{project.year}</ProjectYear>
                <p dangerouslySetInnerHTML={{ __html: project.description }} />
                {project.smallMediaClip && (
                    <ProjectMediaSmall>
                        {project.smallMediaClip}
                    </ProjectMediaSmall>
                )}
                {project.link && <ProjectLink>{project.link}</ProjectLink>}
            </div>
            {project.mediaClip && (
                <ProjectMedia>{project.mediaClip}</ProjectMedia>
            )}
        </ArrangementContainer>
    );
};

export default Arrangement;
