import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';

import FrontPage from './pages/frontpage/FrontPage';

import Menu from './Menu';
import Footer from './Footer';
import Arranger from './pages/arranger/Arranger';
import CV from './pages/cv/CV';
import FilmAndTvDetail from './pages/filmAndTv/FilmAndTvDetail';
import Compositions from './pages/compositions/Compositions';
import FilmAndTv from './pages/filmAndTv/FilmAndTv';

const AppContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    padding: 0 50px;

    @media (max-width: 415px) {
        padding: 0 30px;
    }
`;

const DefaultSize = styled.div`
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
`;

const Main = styled(DefaultSize)`
    flex: 1;
`;

function App() {
    return (
        <AppContainer>
            <BrowserRouter>
                <DefaultSize as="header">
                    <Menu />
                </DefaultSize>
                <Main as="main">
                    <Routes>
                        <Route path="/arranger" element={<Arranger />} />
                        <Route
                            path="/compositions"
                            element={<Compositions />}
                        />
                        <Route path="/cv" element={<CV />} />
                        <Route path="/film-and-tv" element={<FilmAndTv />} />
                        <Route
                            path="/film-and-tv/:slug"
                            element={<FilmAndTvDetail />}
                        />
                        <Route path="/" element={<FrontPage />} />
                    </Routes>
                </Main>
                <DefaultSize as="footer">
                    <Footer />
                </DefaultSize>
            </BrowserRouter>
        </AppContainer>
    );
}

export default App;
