import React from 'react';

const CV: React.FC = () => {
    return (
        <iframe
            title="CV"
            width="100%"
            height="800"
            frameBorder="0"
            src="/cv.pdf"
        />
    );
};

export default CV;
