import React from 'react';
import styled from 'styled-components';
import { projects } from './projects';
import { Link } from 'react-router-dom';
import { useScrollToTop } from '../../shared/useScrollToTop';

const Container = styled.div`
    margin-bottom: 50px;

    *:not(:last-child) {
        padding-right: 50px;
    }

    p {
        margin-bottom: 20px;
    }
`;
const FilmAndTv: React.FC = () => {
    useScrollToTop();
    return (
        <>
            <Container>
                <div style={{ maxWidth: 700 }}>
                    <p>
                        In recent years I have done more work with TV and film,
                        and I did some further education in film music
                        composition at the Inland Norway University of Applied
                        Sciences in 2022 and 2023.
                    </p>
                    <p>
                        I do all sorts of projects with music for film, TV,
                        video games and podcasts.
                    </p>
                </div>
            </Container>
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr',
                    gap: 12,
                }}
            >
                {projects.map((project) => (
                    <Link
                        to={project.slug}
                        key={project.slug}
                        style={{
                            textDecoration: 'none',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'end',
                                flexGrow: 1,
                            }}
                        >
                            <h1
                                style={{
                                    fontSize: 14,
                                    fontWeight: 400,
                                    marginBottom: 5,
                                }}
                            >
                                {project.title}
                            </h1>
                        </div>
                        <img
                            src={project.frontImage}
                            alt=""
                            style={{ width: '100%' }}
                        />
                    </Link>
                ))}
            </div>
        </>
    );
};

export default FilmAndTv;
