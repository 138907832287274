import React from 'react';
import KlaraOgVillsvinetImage from '../../images/arrangements/klaraogvillsvinet.jpg';
import KlaraOgVillsvinetImage2 from '../../images/arrangements/klaraogvillsvinet2.jpg';
import GirlInRedMidnightLove from '../../videos/girl-in-red-midnight-love-2.mp4';
import BMKJaViElsker from '../../videos/BMK-ja-vi-elsker.mp4';
import styled from 'styled-components';

export interface Project {
    title: string;
    commissioner?: string;
    year: number;
    order?: number;
    description: string;
    mediaClip?: React.ReactNode;
    smallMediaClip?: React.ReactNode;
    link?: React.ReactNode;
    isPublished: boolean;
}

const AlbumIframe = styled.iframe`
    width: 370px;
    height: 360px;

    @media (max-width: 767px) {
        width: 100%;
        height: 360px;
    }
`;

const SingleIframe = styled.iframe`
    width: 370px;
    height: 80px;

    @media (max-width: 767px) {
        width: 100%;
        height: 80px;
    }
`;

const popMusicProjects: Project[] = [
    {
        title: 'Birkelunden mannskor and Kajsa Balto - Ja, vi elsker',
        year: 2024,
        description:
            'Arrangement of "Ja, vi elsker", the Norwegian national anthem, for male choir and alto soloist. Screened on NRK on the 17th of May, for the Norwegian national day.',
        smallMediaClip: (
            <video width="100%" controls>
                <source src={BMKJaViElsker} type="video/mp4" />
            </video>
        ),
        isPublished: true,
    },
    {
        title: 'Konradsen',
        year: 2024,
        description:
            'String arrangements for "Michael" and "Thickest Birch" on the album <i>Michael\'s Book on Bears.</i>',
        mediaClip: (
            <div>
                <SingleIframe
                    title="Konradsen - Michael"
                    src="https://open.spotify.com/embed/track/6AwdmyMWjeFKLtbzGjDwwJ"
                    frameBorder={0}
                    allow="encrypted-media"
                ></SingleIframe>
                <SingleIframe
                    title="Konradsen - Thickest Birch"
                    src="https://open.spotify.com/embed/track/4DDKovmIPPUlOrAQXu0TDt"
                    frameBorder={0}
                    allow="encrypted-media"
                ></SingleIframe>
            </div>
        ),
        isPublished: true,
    },
    {
        title: 'Girl in red and KORK',
        year: 2022,
        order: 1,
        description:
            'Orchestral arrangement of "Midnight love", as performed on <i>TV-aksjonen</i> on NRK in 2022.',
        smallMediaClip: (
            <video width="100%" controls>
                <source src={GirlInRedMidnightLove} type="video/mp4" />
            </video>
        ),
        isPublished: true,
    },
    {
        title: 'Gabrielle and KORK ',
        year: 2022,
        order: 2,
        description:
            'Orchestral arrangements of three songs by Gabrielle for a live concert with KORK.',

        link: (
            <a href="https://tv.nrk.no/serie/kork-hele-landets-orkester/2022/MKKA11001322/avspiller">
                Watch the full concert on NRK TV
            </a>
        ),
        isPublished: true,
    },
    {
        title: 'Dagny and KORK',
        year: 2020,
        description:
            'Orchestral arrangement of three songs on a live-concert with Dagny and KORK. I also acted as music producer for the recording session.',
        smallMediaClip: (
            <>
                <iframe
                    title="Dagny and KORK - Please look at me:"
                    width="100%"
                    height="290"
                    src="https://www.youtube-nocookie.com/embed/GL_jCZ3WSUY"
                    frameBorder="0"
                    allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
                <iframe
                    title="Dagny and KORK - Drink about"
                    width="100%"
                    height="290"
                    src="https://www.youtube-nocookie.com/embed/FGxtvC5WYG8"
                    frameBorder="0"
                    allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
            </>
        ),
        isPublished: true,
    },
    {
        title: 'Marthe Wang and KORK - No sier eg adjø',
        year: 2020,
        description:
            'Orchestral arrangement of Marthe Wang´s song "No sier eg adjø", with Trygve Skaug on guitar',
        smallMediaClip: (
            <iframe
                title="Marthe Wang - No sier eg adjø"
                width="100%"
                height="290"
                src="https://www.youtube-nocookie.com/embed/nK6KByY1mX8"
                frameBorder="0"
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
        ),
        isPublished: true,
    },
    {
        title: 'Astrid S and KORK - Når snøen smelter',
        year: 2021,
        description:
            'Arrangement of the song "Når snøen smelter", performed with a string quartet on the TV shows Lindmo and Kvelden før kvelden, and with full orchestra on the KORK christmas concert of 2021.',
        link: (
            <a href="https://www.nrk.no/video/musikk/astrid-s-og-kork--naar-snoen-smelter_b8ddfee3-ba2a-4516-b971-58f9ae90c4b6">
                Watch the orchestral version on NRK TV
            </a>
        ),

        smallMediaClip: (
            <iframe
                title="Astrid S and KORK - Når snøen smelter"
                width="100%"
                height="290"
                src="https://www.youtube-nocookie.com/embed/xJDCDaJUAQs"
                frameBorder="0"
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
        ),
        isPublished: true,
    },
    {
        title: 'Ellen Andrea Wang and KORK ',
        year: 2021,
        description:
            'Orchestral arrangements of two songs for a live concert with Ellen Andrea Wang and Kork, with Hanna Paulsberg on saxophone. I also acted as music producer for the recording session.',

        link: (
            <a href="https://www.nrk.no/video/musikk/ellen-andrea-wang-og-kork--there-is-a-place_d1660119-a2bf-4d14-a453-d82943be2479">
                Watch the performance of "There is a place" on NRK TV
            </a>
        ),
        isPublished: true,
    },
    {
        title: 'Frida Ånnevik and KORK',
        year: 2020,
        description:
            'Arrangement of three of Frida Ånnevik´s songs for her concert with KORK.',

        link: (
            <a href="https://tv.nrk.no/serie/kork-hele-landets-orkester/2020/MKKA11002020">
                Watch the concert on NRK TV
            </a>
        ),
        isPublished: true,
    },
    {
        title: 'Bakkekontakt',
        commissioner: 'Marthe Wang',
        year: 2020,
        description:
            'String arrangements for "Ingen av oss visste", "Komet" and "Du sa det til meg" on the album <i>Bakkekontakt</i>.',
        mediaClip: (
            <div>
                <SingleIframe
                    title="Marthe Wang - Ingen av oss visste"
                    src="https://open.spotify.com/embed/track/4Hh3v8o6WOJA6JWOptiFHP"
                    frameBorder={0}
                    allow="encrypted-media"
                ></SingleIframe>
                <SingleIframe
                    title="Marthe Wang - Komet"
                    src="https://open.spotify.com/embed/track/03XoQzFFyzyyoWRb2H0IPW"
                    frameBorder={0}
                    allow="encrypted-media"
                ></SingleIframe>
                <SingleIframe
                    title="Marthe Wang - Du sa det til meg"
                    src="https://open.spotify.com/embed/track/1peagKaaJ9PlhitZLwLFjK"
                    frameBorder={0}
                    allow="encrypted-media"
                ></SingleIframe>
            </div>
        ),
        isPublished: true,
    },
];

const largeEnsembleProjects: Project[] = [
    {
        title: 'Yoshinori Hayashi: Ambivalence',
        commissioner: 'Oslo Classics Festival and KORK',
        year: 2019,
        description:
            'Performed at the Oslo Classics Festival, a festival aiming to bridge the gap between electronic and classical music. Arrangements for electronica artist Yoshinori Hayashi and orchestra.',
        link: (
            <a href="https://radio-we.nrk.no/serie/paa-konsert-med-kork/MKKL15004119/09-10-2019/avspiller#t=0h25m25s">
                Listen to the live performance on NRK Radio
            </a>
        ),
        isPublished: true,
    },
    {
        title: 'The Heights of the Reeds',
        commissioner: 'Opera North Orchestra in Leeds',
        year: 2017,
        description:
            'Originally written in 2017 as a sound-walk across the Humber Bridge near Leeds, and released as a record in 2018. Composition by Eivind Aarset, Jan Bang, Arve Henriksen and Jez Riley-French. Nominated for Spellemannsprisen 2019. I wrote the orchestral and choral arrangements.',
        mediaClip: (
            <AlbumIframe
                title="Heights of the Reeds"
                src="https://open.spotify.com/embed/album/04B7mvuoqmjkOxGcAJazAe"
                frameBorder="0"
                allow="encrypted-media"
            />
        ),
        smallMediaClip: (
            <iframe
                title="The Height of the Reeds"
                width="100%"
                height="290"
                src="https://www.youtube-nocookie.com/embed/qg4RctdI35I"
                frameBorder="0"
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
        ),
        isPublished: true,
    },
    {
        title: 'The Music Factory',
        commissioner: 'Oslo Philharmonic Orchestra',
        year: 2017,
        description: 'Arrangements written together with Helge Sunde.',
        smallMediaClip: (
            <iframe
                title="Musikkfabrikken"
                width="100%"
                height="290"
                src="https://www.youtube-nocookie.com/embed/eSBQTeU6bDg"
                frameBorder="0"
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
        ),
        isPublished: true,
    },
];

const smallEnsembleProjects: Project[] = [
    {
        title: 'Klara, villsvinet og den lille musikkfabrikken',
        commissioner:
            'Wakka Wakka, Riksteatret and the Oslo Philharmonic Orchestra',
        year: 2020,
        description:
            'Arrangements for chamber ensemble for a childrens play with the internationally renowned theatre company Wakka Wakka, Riksteatret and musicians from the Oslo Philharmonic Orchestra.',
        isPublished: true,
        mediaClip: (
            <img
                style={{ width: '100%' }}
                src={KlaraOgVillsvinetImage}
                alt=""
            />
        ),
        smallMediaClip: (
            <img
                style={{ width: '100%' }}
                src={KlaraOgVillsvinetImage2}
                alt=""
            />
        ),
        link: (
            <a href="https://www.riksteatret.no/repertoar/klara-villsvinet-og-den-lille-musikkfabrikken/?fbclid=IwAR2xpdCLRr-cIQdFaeDcbYOXjpfMKsfsW6N5zqN9Pm19j_8ky5gUMBSk9Qs">
                Read more about the play here
            </a>
        ),
    },
];

export default popMusicProjects
    .concat(largeEnsembleProjects)
    .concat(smallEnsembleProjects)
    .filter((project) => project.isPublished);
