import React from 'react';
import styled from 'styled-components';

const FooterWrapper = styled.div`
    font-size: 14px;
    height: 120px;
    margin-bottom: -120px;

    @media (max-width: 650px) {
        height: 170px;
        margin-bottom: -170px;
    }
`;

const FooterHeader = styled.h2`
    font-size: 16px;
    font-weight: 400;
    margin: 50px 0 5px;
`;

const FooterList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    @media (max-width: 650px) {
        flex-direction: column;

        ul {
            margin-bottom: 10px;
        }
    }
`;

const Footer: React.FC = () => {
    return (
        <FooterWrapper>
            <FooterHeader>Contact</FooterHeader>
            <FooterList>
                <ul>
                    <li>aleksander.waaktaar@gmail.com</li>
                    <li>+47 480 09 644</li>
                </ul>
                <ul>
                    <li>Aleksander Sinding-Larsen Waaktaar</li>
                    <li>Åsengata 8b, 0480 Oslo, Norway</li>
                </ul>
            </FooterList>
        </FooterWrapper>
    );
};

export default Footer;
