import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import SoloProjectImage from '../../images/soloProject/soloproject.png';
import { useScrollToTop } from '../../shared/useScrollToTop';

const Container = styled.div`
    display: flex;
    justify-content: space-between;
`;

const StyledLink = styled(Link)`
    display: block;
    font-weight: 400;
    line-height: 1.3;
    font-size: 50px;
    position: relative;
    text-decoration: none;
    margin-top: 40px;
    width: 100%;
    cursor: pointer;
    transition: 0.3s transform;

    &:hover {
        transform: skewX(-20deg);
    }
`;

const HeadLiner = styled.p`
    max-width: 350px;
    margin-bottom: 60px;
`;

const Image = styled.img`
    width: 100%;
    max-width: 500px;
    margin-left: 40px;
    object-fit: cover;

    @media (max-width: 809px) {
        display: none;
    }
`;

const FrontPage: React.FC = () => {
    useScrollToTop();

    return (
        <Container>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                }}
            >
                <HeadLiner>
                    Oslo based composer, songwriter, singer, arranger and music
                    producer, writing commissions and arrangements for all types
                    of ensembles. I also work with music for film and TV.
                </HeadLiner>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'end',
                        flexGrow: 1,
                    }}
                >
                    <div style={{ width: '100%' }}>
                        <StyledLink to="/compositions">Compositions</StyledLink>
                        <StyledLink to="/film-and-tv">
                            Works for film and TV
                        </StyledLink>
                        <StyledLink to="/arranger">Arrangements</StyledLink>
                    </div>
                </div>
            </div>
            <Image alt="" src={SoloProjectImage} />
        </Container>
    );
};

export default FrontPage;
