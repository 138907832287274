import React from 'react';
import SykkelsommerFrontImage from '../../images/filmAndTv/sykkelsommer/front.jpg';
import FestenEtterFastenFrontImage from '../../images/filmAndTv/festen-etter-fasten/front.png';
import InMyMothersHeartFrontImage from '../../images/filmAndTv/in-my-mothers-heart/front.png';
import InMyMothersHeart1Image from '../../images/filmAndTv/in-my-mothers-heart/1.png';
import InMyMothersHeart2Image from '../../images/filmAndTv/in-my-mothers-heart/2.png';
import InMyMothersHeart3Image from '../../images/filmAndTv/in-my-mothers-heart/3.png';
import InMyMothersHeart4Image from '../../images/filmAndTv/in-my-mothers-heart/4.png';
import InMyMothersHeart5Image from '../../images/filmAndTv/in-my-mothers-heart/5.png';
import InMyMothersHeart6Image from '../../images/filmAndTv/in-my-mothers-heart/6.png';

import TvillingFrontImage from '../../images/filmAndTv/tvilling/front.png';
import Tvilling1Image from '../../images/filmAndTv/tvilling/1.png';
import Tvilling2Image from '../../images/filmAndTv/tvilling/2.png';
import Tvilling3Image from '../../images/filmAndTv/tvilling/3.png';

import SideOmSideFrontImage from '../../images/filmAndTv/side-om-side/front.png';

import DescentIntoTheMaelstromFrontImage from '../../images/filmAndTv/descent-into-the-maelstrom/front.png';
import DescentIntoTheMaelstrom1Image from '../../images/filmAndTv/descent-into-the-maelstrom/1.png';
import DescentIntoTheMaelstrom2Image from '../../images/filmAndTv/descent-into-the-maelstrom/2.png';

import styled from 'styled-components';

const AlbumIframe = styled.iframe`
    width: 100%;
    height: 360px;
`;

interface Project {
    title: string;
    frontImage: string;
    slug: Slug;
}

type Tag =
    | 'Short film'
    | 'Feature film'
    | 'TV series'
    | 'TV show'
    | 'Documentary'
    | 'Drama'
    | 'Comedy';

interface ProjectDetails {
    metadata: Project;
    tags: Tag[];
    images: string[];
    description: string;
    mediaClip?: React.ReactNode;
    secondaryMediaClip?: React.ReactNode;
    credit?: string;
}

export const slugs = [
    'in-my-mothers-heart',
    'sykkelsommer',
    'festen-etter-fasten',
    'zen',
    'side-om-side-christmas',
    'descent-into-the-maelstrom',
] as const;

export type Slug = (typeof slugs)[number];

const inMyMothersHeart: Project = {
    title: 'In My Mothers Heart (2023)',
    frontImage: InMyMothersHeartFrontImage,
    slug: 'in-my-mothers-heart',
};

const sykkelsommer: Project = {
    title: 'Sykkelsommer (2023)',
    frontImage: SykkelsommerFrontImage,
    slug: 'sykkelsommer',
};
const festenEtterFasten: Project = {
    title: 'Festen etter fasten (2022)',
    frontImage: FestenEtterFastenFrontImage,
    slug: 'festen-etter-fasten',
};
const zen: Project = {
    title: 'Zen, eller kunsten å vedlikeholde en motorsykkel (2023)',
    frontImage: TvillingFrontImage,
    slug: 'zen',
};
const sideOmSideJul: Project = {
    title: 'Side om side, christmas episode (2019)',
    frontImage: SideOmSideFrontImage,
    slug: 'side-om-side-christmas',
};
const descentIntoTheMalstroem: Project = {
    title: 'A Descent Into the Maelstrom (2018)',
    frontImage: DescentIntoTheMaelstromFrontImage,
    slug: 'descent-into-the-maelstrom',
};

export const projects: Project[] = [
    inMyMothersHeart,
    zen,
    sykkelsommer,
    festenEtterFasten,
    sideOmSideJul,
    descentIntoTheMalstroem,
];

export const details: Record<Slug, ProjectDetails> = {
    sykkelsommer: {
        metadata: sykkelsommer,
        tags: ['TV show'],
        images: [],
        description:
            'Composed vignettes and underscore for the Norwegian TV-show “Sykkelsommer” performed by the Norwegian broadcasting orchestra. I also produced the recording session.',
        mediaClip: (
            <>
                <iframe
                    width="100%"
                    height="340"
                    allow="autoplay"
                    frameBorder="0"
                    title="Vignettes and underscore for Sykkelsommer on NRK"
                    src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1786100526&color=%23A2C2D7&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false&visual=false"
                />
                <a href="https://tv.nrk.no/serie/sykkelsommer">
                    Watch the show on NRK TV
                </a>
            </>
        ),
    },
    'festen-etter-fasten': {
        metadata: festenEtterFasten,
        tags: ['TV show'],
        images: [],
        description:
            'Co-wrote and arranged the vignette for the Norwegian TV-show “Festen etter fasten” performed by the Norwegian broadcasting orchestra (KORK).',
        mediaClip: (
            <a href="https://tv.nrk.no/se?v=MUHU25000022">
                Watch the episode on NRK TV
            </a>
        ),
    },
    'in-my-mothers-heart': {
        metadata: inMyMothersHeart,
        tags: ['Short film', 'Documentary'],
        images: [
            InMyMothersHeartFrontImage,
            InMyMothersHeart1Image,
            InMyMothersHeart2Image,
            InMyMothersHeart3Image,
            InMyMothersHeart4Image,
            InMyMothersHeart5Image,
            InMyMothersHeart6Image,
        ],
        description:
            'Bachelor exam film shot and directed by Karrar Al-Azzawi.',
        mediaClip: (
            <iframe
                width="100%"
                height="340"
                allow="autoplay"
                frameBorder="0"
                title="Screen music for the movie In My Mother's Heart"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1786098453&color=%23A2C2D7&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false&visual=false"
            />
        ),
        credit: 'Direction and cinematography: Karrar Al-Azzawi',
    },
    zen: {
        metadata: zen,
        tags: ['Short film', 'Drama'],
        images: [
            TvillingFrontImage,
            Tvilling1Image,
            Tvilling2Image,
            Tvilling3Image,
        ],
        description:
            'Student film directed by Jens Glomnes Hertzberg (not available for public viewing).',
        mediaClip: (
            <iframe
                width="100%"
                height="340"
                allow="autoplay"
                title="Screen music for the movie Zen, eller kunsten å vedlikeholde en motorsykkel"
                frameBorder="0"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1786099419&color=%23A2C2D7&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false&visual=false"
            />
        ),
    },
    'side-om-side-christmas': {
        metadata: sideOmSideJul,
        tags: ['TV series', 'Comedy'],
        images: [],
        description:
            'Composed underscore for the ending of “Side om side’s” Christmas special, as well as arranged the title song for the Norwegian broadcasting orchestra (KORK). I also produced the recording session.',
        mediaClip: (
            <a href="https://tv.nrk.no/serie/side-om-side/sesong/7/episode/11/avspiller">
                Watch the episode on NRK TV
            </a>
        ),
    },
    'descent-into-the-maelstrom': {
        metadata: descentIntoTheMalstroem,
        tags: ['Documentary'],
        images: [
            DescentIntoTheMaelstromFrontImage,
            DescentIntoTheMaelstrom1Image,
            DescentIntoTheMaelstrom2Image,
        ],
        description:
            'Orchestral arrangement for Arctic Philharmonic of A Descent Into the Maelstrom by Philip Glass (2018) commissioned by Jan Vardøen. The arrangement is used as the soundtrack for Vardøens documentary with the same name, a documentary which won the documentary category at Bergen International Film Festival 2019. I also co-produced the recording.',
        mediaClip: (
            <AlbumIframe
                title="A Descent into the Maelstrom"
                src="https://open.spotify.com/embed/album/4s36q4Aorx4adcOu0XPHHo"
                frameBorder="0"
                allow="encrypted-media"
            />
        ),
        secondaryMediaClip: (
            <iframe
                title="A Descent into the Maelstrom"
                width="100%"
                height="360"
                src="https://www.youtube-nocookie.com/embed/--xgAWHnqSg"
                frameBorder="0"
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
        ),
    },
};
